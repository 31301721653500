import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenKey = 'authToken';
  private userDataKey = 'authUserData';
  private loggedIn = new BehaviorSubject<boolean>(this.hasToken());

  isLoggedIn$ = this.loggedIn.asObservable(); // Observável para outras partes da aplicação

  constructor(private router: Router) {}

  // Método para verificar login status (sincroniza com o Guard)
  isLoggedIn(): boolean {
    return this.hasToken();
  }

  // Salva o token e os dados do usuário no localStorage
  setAuthData(token: string, userData: any): void {
    localStorage.setItem(this.tokenKey, token);
    localStorage.setItem(this.userDataKey, JSON.stringify(userData));
    this.loggedIn.next(true);
  }

  // Retorna os dados do usuário armazenados localmente
  getUserData(): any | null {
    const userData = localStorage.getItem(this.userDataKey);
    return userData ? JSON.parse(userData) : null;
  }

  // Remove o token e atualiza o estado de login
  logout(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.userDataKey);
    this.loggedIn.next(false);
    this.router.navigate(['/']);
  }

  // Retorna um true or false para o token
  private hasToken(): boolean {
    return !!localStorage.getItem(this.tokenKey);
  }

}
